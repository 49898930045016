<template>
  <div class="flex flex-col gap-2 absolute top-20 left-6 z-1000 mt-2">
    <div class="inline-flex flex-col gap-4 items-start bg-mainGrey px-6 py-4 rounded">
      <div class="flex gap-3">
        <img @click="toggleMapLayer(true)" :src="currentSelected ? checkedIcon : uncheckedIcon" alt="checkbox">
        <label for="current" class="inline-block font-body text-bodyL dark:text-white text-sepiaBlack my-auto">{{ $t('map.current') }}</label>
      </div>
      <div class="flex gap-3">
        <img @click="toggleMapLayer(false)" :src="currentSelected ? uncheckedIcon : checkedIcon" alt="checkbox">
        <label for="old" class="inline-block font-body text-bodyL dark:text-white text-sepiaBlack my-auto">{{ $t('map.old') }}</label>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';

  const props = defineProps({
    changeMap: Function,
  });

  const currentSelected = ref(true);

  const checkedIcon = require('../../assets/icons/radioChecked.svg');
  const uncheckedIcon = require('../../assets/icons/radioUnchecked.svg');

  const toggleMapLayer = (newValue) => {
    currentSelected.value = newValue;
    props.changeMap(!newValue);
  };
</script>