<template>
  <div class="h-full w-full overflow-hidden rounded-t-xl">
    <Transition>
      <l-map
        :useGlobalLeaflet="false"
        :minZoom="minZoom"
        :maxBounds="maxBounds"
        :zoom="zoom"
        :center="center"
        @update:zoom="handleZoomChange"
        :options="{zoomControl: false}"
      >
        <l-tile-layer :url="url" :attribution="attribution" />
        <l-geo-json
          :geojson="currentGeoJson"
          :optionsStyle="showOld
            ? feature => featureStyleTransparent(feature, zoom)
            : feature => featureStyleStandard(feature, zoom)"
          />
        <l-geo-json
          v-if="showOld"
          :geojson="oldGeoJson"
          :optionsStyle="feature => featureStyleStandard(feature, zoom)"
        />
        <Transition>
          <Legend />
        </Transition>
        <Transition>
          <ZoomControls :zoomIn="zoomIn" :zoomOut="zoomOut" />
        </Transition>
        <Transition>
          <LayerControls :changeMap="changeMap" />
        </Transition>
        <Transition>
          <DetailsModal
            v-if="clickedIndex != null"
            :market="locations[clickedIndex]"
            :unselectMarket="unselectMarket"
            :changeView="props.changeView"
            :index="clickedIndex"
          />
        </Transition>
        <l-marker
          v-for="(location, index) in locations"
          :lat-lng="[location.location_data[0][1], location.location_data[0][2]]"
          @click="getClickedLocation(index)"
        >
          <l-icon :icon-anchor="staticAnchor" class-name="z-0">
            <CustomMarker
              :index="index"
              :clickedLocation="clickedIndex"
            />
          </l-icon>
        </l-marker>

        <!-- County names as image overlays -->
        <l-image-overlay
          v-if="zoom <= 9 && showOld"
          :url="require('@/assets/county_names/cluj.png')"
          :bounds="[
            [46.775849, 23.453578],
            [46.925433, 23.697864],
          ]"
        />
        <l-image-overlay
          v-if="zoom <= 9 && showOld"
          :url="require('@/assets/county_names/turda.png')"
          :bounds="[
            [46.489001, 23.614106],
            [46.645158, 23.857081],
          ]"
        />
        <l-image-overlay
          v-if="zoom <= 9 && showOld"
          :url="require('@/assets/county_names/tarnava.png')"
          :bounds="[
            [46.271090, 24.211582],
            [46.392023, 24.643206],
          ]"
        />
        <l-image-overlay
          v-if="zoom <= 9 && showOld"
          :url="require('@/assets/county_names/odorhei.png')"
          :bounds="[
            [46.282500, 25.073578],
            [46.431090, 25.441582],
          ]"
        />
        <l-image-overlay
          v-if="zoom <= 9 && showOld"
          :url="require('@/assets/county_names/ciuc.png')"
          :bounds="[
            [46.462500, 25.723578],
            [46.621090, 25.881582],
          ]"
        />
        <l-image-overlay
          v-if="zoom <= 9 && showOld"
          :url="require('@/assets/county_names/neamt.png')"
          :bounds="[
            [47.002500, 26.073578],
            [47.051090, 26.271582],
          ]"
        />
        <l-image-overlay
          v-if="zoom <= 9 && showOld"
          :url="require('@/assets/county_names/nasaud.png')"
          :bounds="[
            [47.172500, 24.543578],
            [47.301090, 24.801582],
          ]"
        />
        <l-image-overlay
          v-if="zoom <= 9 && showOld"
          :url="require('@/assets/county_names/campulung.png')"
          :bounds="[
            [47.372500, 25.113578],
            [47.501090, 25.501582],
          ]"
        />
      </l-map>
    </Transition>
  </div>
</template>

<script setup>
  import { ref, onBeforeMount } from 'vue';
  import 'leaflet/dist/leaflet.css';
  import { LMap, LTileLayer, LGeoJson, LMarker, LIcon, LImageOverlay } from '@vue-leaflet/vue-leaflet';
  
  import ZoomControls from './ZoomControls.vue';
  import LayerControls from './LayerControls.vue';
  import Legend from './Legend.vue';
  import CustomMarker from './CustomMarker.vue';
  import DetailsModal from './DetailsModal.vue';

  const props = defineProps({
    locations: Array,
    clickedIndex: Number,
    changeView: Function,
    setSelectedMarker: Function,
  });

  const zoom = ref(9);
  const minZoom = ref(8);
  const center = ref([46.737716, 24.927486]);
  const maxBounds = ref([
    [45.762542, 22.404799],
    [47.911556, 27.450014],
  ]);
  const staticAnchor = ref([22, 51]);
  const url = ref('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png');
  const attribution = ref('© CartoDB');

  const currentGeoJson = ref(null);
  const oldGeoJson = ref(null);
  const showOld = ref(false);

  const currentGeoJsonUrl = '../geojson/Mures_county_current.geojson';
  const oldGeoJsonUrl = '../geojson/Mures_county_old.geojson';

  function loadGeoJSON(geojsonUrl, geoJson) {
    fetch(geojsonUrl)
      .then(response => response.json())
      .then(geoJsonData => {
        geoJson.value = geoJsonData;
      })
      .catch(error => {
        console.error('Error loading GeoJSON:', error);
      });
  }

  const featureStyleStandard = (feature, zoom) => {
    const color = feature.properties.color || '#DA723C';

    const lineWidthFactor = 0.06;
    const width = feature.properties.width || 5;
    const adjustedWidth = width * zoom * lineWidthFactor;
    
    return {
      fillColor: 'none',
      weight: adjustedWidth,
      opacity: 1,
      color: color,
      dashArray: feature.properties.dashed ? '2, 9' : 'none',
    };
  };

  const featureStyleTransparent = (feature, zoom) => {
    const color = feature.properties.color || '#DA723C';

    const lineWidthFactor = 0.06;
    const width = feature.properties.width || 5;
    const adjustedWidth = width * zoom * lineWidthFactor;
    
    return {
      fillColor: 'none',
      weight: adjustedWidth,
      opacity: 0.5,
      color: color,
      dashArray: feature.properties.dashed ? '2, 9' : 'none',
    };
  };

  const handleZoomChange = newZoom => {
    zoom.value = newZoom;
  };

  const zoomIn = () => {
    zoom.value += 1;
  };

  const zoomOut = () => {
    zoom.value -= 1;
  };

  const changeMap = (newValue) => {
    showOld.value = newValue;
  };

  const getClickedLocation = (location) => {
    props.setSelectedMarker(location);
  };

  const unselectMarket = () => {
    props.setSelectedMarker(null);
  };

  onBeforeMount(() => {
    loadGeoJSON(currentGeoJsonUrl, currentGeoJson);
    loadGeoJSON(oldGeoJsonUrl, oldGeoJson);
  });
</script>

<style scoped>
  .v-enter-active, .v-leave-active {
    transition: opacity 0.3s ease;
  }

  .v-enter-from, .v-leave-to {
    opacity: 0;
  }
</style>