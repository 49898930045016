<template>
  <div class="relative marker left-11">
    <img :src="svg" alt="marker" />
    <button
      class="flex items-center justify-center absolute circle bg-white rounded-full"
    >
      <span
        class="font-bold text-h5 text-black"
      >
        {{ props.index + 1 }}
      </span>
    </button>
  </div>
</template>

<script setup>
  const props = defineProps({
    index: Number,
  });

  const svg = require('../../assets/icons/markerOrangeLarge.svg');
</script>

<style scoped>
  .marker {
    top: -33px;
  }
  .circle {
    top: 9px;
    left: 9px;
    width: 46px;
    height: 46px;
  }
</style>