<template>
  <div class="inline-flex flex-col gap-4 items-start bg-legendWhite px-6 py-4 rounded absolute bottom-6 left-6 z-1000 w-60">
    <h6 class="inline-block font-heading text-h6 text-mainGrey my-auto">{{ $t('map.caption') }}</h6>
    <div class="flex flex-col gap-2">
      <div class="flex gap-2 items-center">
        <img src="../../assets/icons/lineOrange.svg" alt="line" class="w-6 h-6">
        <span class="inline-block font-body text-bodyL text-mainGrey my-auto">{{ $t('map.contCurrent') }}</span>
      </div>
      <div class="flex gap-2 items-center">
        <img src="../../assets/icons/lineBlack.svg" alt="line" class="w-6 h-6">
        <span class="inline-block font-body text-bodyL text-mainGrey my-auto">{{ $t('map.contPast') }}</span>
      </div>
      <div class="flex gap-2 items-center">
        <img src="../../assets/icons/lineDashedBlack.svg" alt="line" class="w-6 h-6">
        <span class="inline-block font-body text-bodyL text-mainGrey my-auto">{{ $t('map.neighbors') }}</span>
      </div>
      <div class="border-t border-mainGrey border-opacity-20 my-1"></div>
      <div v-for="marketType in marketTypes" class="flex gap-2 items-center">
        <img :src="marketType.image_url" alt="market" class="w-6 h-6">
        <span class="inline-block font-body text-bodyL text-mainGrey my-auto">{{ marketType.name }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
  import axios from 'axios';
  import { onBeforeMount, ref, watch } from 'vue';
  import { languageStore } from '../../store/store';

  const baseURL = process.env.VUE_APP_AXIOS_URL;
  const marketTypes = ref([]);

  const getMarketTypes = () => {
    axios.get(baseURL + `/wp-json/wp/v2/location_types`, {params: {lang: languageStore.language}}, {
      headers: {
              'Access-Control-Allow-Origin': '*'
      }
    }).then(response => {
        marketTypes.value = response.data[0][0];
    }).catch( (error) => {
        console.log(error);
    }); 
  }

  watch(languageStore, () => {
    getMarketTypes();
  });

  onBeforeMount(() => {
    getMarketTypes();
  });
</script>