<template>
  <div class="h-screen w-screen flex flex-col">
    <Header />
    <div v-if="detailsClicked" class="flex-1 w-full px-10 pt-5">
      <DetailsPage :changeView="changeView" :selectedMarker="selectedMarker" />
    </div>
    <div v-else class="flex-1 w-full px-10 pt-5">
      <Map
        class="rounded-tl-xl rounded-tr-xl"
        :locations="locations"
        :clickedIndex="clickedIndex"
        :changeView="changeView"
        :setSelectedMarker="setSelectedMarker"
      />
    </div>
  </div>
</template>

<script setup>
  import axios from 'axios';
  import Header from "@/components/Header.vue";
  import Map from '@/components/map/Map.vue';
  import DetailsPage from '@/components/map/DetailsPage.vue';

  import { languageStore } from '../../store/store';

  import { onBeforeMount, ref, watch } from 'vue';

  const baseURL = process.env.VUE_APP_AXIOS_URL;

  const detailsClicked = ref(false);
  const locations = ref([]);
  const clickedIndex = ref(null);
  const selectedMarker = ref(null);

  const getMarkets = () => {
    axios.get(baseURL + `/wp-json/wp/v2/locations`, {params: {lang: languageStore.language}}, {
      headers: {
              'Access-Control-Allow-Origin': '*'
      }
    }).then(response => {
        locations.value = response.data;
    }).catch( (error) => {
        console.log(error);
    });
  }

  const changeView = () => {
    detailsClicked.value = !detailsClicked.value;
  }

  const setSelectedMarker = (marker) => {
    clickedIndex.value = marker;

    if (marker == null) {
      selectedMarker.value = null;
      return;
    }

    axios.get(baseURL + `/wp-json/wp/v2/location/${locations.value[marker].id}`, {params: {lang: languageStore.language}}, {
      headers: {
              'Access-Control-Allow-Origin': '*'
      }
    }).then(response => {
        selectedMarker.value = response.data;
    }).catch( (error) => {
        console.log(error);
    });
  }

  watch(languageStore, () => {
    getMarkets();

    if (selectedMarker.value != null) {
      axios.get(baseURL + `/wp-json/wp/v2/location/${selectedMarker.value.transtations[languageStore.language]}`, {lang: languageStore.language}, {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      }).then(response => {
        selectedMarker.value = response.data;
      }).catch((error) => {
        console.log(error);
      });
    }
  });

  onBeforeMount(() => {
    getMarkets();
  });
</script>

<style scoped>
  .flex-1 {
    flex: 1;
  }
</style>