<template>
  <div>
    <img :src="props.index == props.clickedLocation ? dropSvgOrange : dropSvgBlack" alt="marker" />
    <button
      class="flex items-center justify-center absolute top-1.5 left-1.5 w-8 h-8 rounded-full"
      :class="props.index == props.clickedLocation ? 'bg-white' : 'bg-redDamask'"
    >
      <span
        class="font-heading text-bodyL"
        :class="props.index == props.clickedLocation ? 'text-black' : 'text-white'"
      >
        {{ props.index + 1 }}
      </span>
    </button>
  </div>
</template>

<script setup>
  const props = defineProps({
    index: Number,
    clickedLocation: Number,
  });

  const dropSvgBlack = require('../../assets/icons/markerBlack.svg');
  const dropSvgOrange = require('../../assets/icons/markerOrange.svg');
</script>
